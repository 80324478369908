import React, {Component} from "react";
import {CardElement, injectStripe} from "react-stripe-elements";
import ErrorMessage from "../Elements/ErrorMessage";
import parseJsonResponse from "../Utilities/JsonParser";

class CheckoutForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			completed: false,
			value: "",
			busy: false,
			feedback: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	handleChange(event) {
		this.setState({ value: event.target.value });
	}

	submit(event) {
		event.preventDefault();
		const task = async () => {
			this.setState({ busy: true, feedback: "" });
			let {token} = await this.props.stripe.createToken({name: "Name"});
			if(!token) { return; }

			const uri = "/products/order/"+this.props.promoCode;
			const body = {
				token: token.id,
				sku_ids: this.props.skus,
				email: this.state.value,
				meta: {
					host_id: this.props.hostId,
				},
			};
			if(this.props.coupon !== "null") {
				body.meta.coupon = this.props.coupon;
			}

			await fetch(uri, {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(body),
			}).then(parseJsonResponse);

			this.setState({ completed: true });
		};

		task().catch(err => {
			this.setState({ feedback: err || "Unexpected error occurred" });
		}).finally(() => {
			this.setState({ busy: false });
		});
	}

	closeModal() {
		this.props.onClose();
		this.setState({
			completed: false,
			value: "",
			busy: false,
		});
	}

	render() {
		if(this.state.completed) {
			return (
				<div>
					<h1>Purchase Complete!</h1>
					<button className="btn success" onClick={this.closeModal}>Close</button>
				</div>
			);
		}

		let buttonPanel;
		if(!this.state.busy) {
			buttonPanel = <div>
				<button type="button" className="btn danger" id="stripe-cancel" onClick={this.closeModal}>Cancel</button>
				<button type="submit" className="btn success" id="stripe-purchase">Purchase</button>
			</div>;
		}

		return (
			<div className="checkout">
				<form onSubmit={this.submit}>
					<p>${this.props.total / 100}</p>
					<input id="stripe-email" required type="email" placeholder="Email" onChange={this.handleChange} value={this.state.value} />
					<hr/>
					<CardElement style={{base: {fontSize: "22px", margin: "10px"}}} />
					<hr/>
					<ErrorMessage className="error" id="stripe-error">
						{this.state.feedback}
					</ErrorMessage>
					{buttonPanel}
				</form>
			</div>
		);
	}
}

export default injectStripe(CheckoutForm);
