import styled from "styled-components";

const ModalBackground = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.5);
	z-index: -1;
`;

export default ModalBackground;
