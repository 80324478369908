import React from "react";
import styled from "styled-components";
import logo from "../img/logo.png";
import img from "../img/header.jpg";

const Header = () => (
	<StyledHeader>
		<Ribbon>
			<RibbonText>Introductory Price!!</RibbonText>
		</Ribbon>
		<HeaderLogo id="logo" src={logo} alt="Sequence Blocks" />
		<Title>Sequence Blocks</Title>
		<p>
			<StyledLink href="https://www.blueforge.us">
				buy it now on Blueforge.us!
			</StyledLink>
		</p>
	</StyledHeader>
);

const StyledHeader = styled.header`
	background-color: #557799;
	background-image: url(${img});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	line-height: 1.2;
	padding: 10vw 2em;
	text-align: center;
`;

const Ribbon = styled.div`
	width: 250px;
	height: 250px;
	overflow: hidden;
	position: absolute;
	top: -25px;
	left: -25px;
`;

const RibbonText = styled.span`
	position: absolute;
	display: block;
	width: 425px;
	padding: 15px 0;
	background-color: #e19b53;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	color: #fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	text-align: center;
	right: -75px;
	top: 100px;
	transform: rotate(-45deg);
`;

const HeaderLogo = styled.img`
	display: inline-block;
	height: 120px;
	vertical-align: top;
`;

const Title = styled.h1`
	color: white;
	font-size: 2.5em;
	font-weight: 300;
`;

const StyledLink = styled.a`
	border: 2px solid #e19b53;
	border-radius: 290486px;
	color: white;
	font-size: 0.6em;
	letter-spacing: 0.2em;
	padding: 1em 2em;
	text-transform: uppercase;
	text-decoration: none;
	transition: none 200ms ease-out;
	transition-property: color, background;
	:hover {
		background: #e19b53;
		color: white;
	}
`;

export default Header;
