import React from "react";
import Header from "./Components/Header";
import Solicit from "./Components/Solicit";
import Paragraphs from "./Components/Paragraphs";
import Footer from "./Components/Footer";

const App = () => (
	<div>
		<Header />
		<main id="sequence-blocks-page">
			<hr />
			<Solicit />
			<hr />
			<Paragraphs />
			<Footer />
		</main>
	</div>
);

export default App;
