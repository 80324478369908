import React, { Component } from "react";
import StyledButton from "../Elements/StyledButton";
import ErrorMessage from "../Elements/ErrorMessage";
import StyledInput from "../Elements/StyledInput";
import parseJsonResponse from "../Utilities/JsonParser";

export default class SignupPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			submitted: false,
			feedback: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		this.setState({ email: event.target.value });
	}

	handleSubmit(event) {
		event.preventDefault(); // prevent page refresh
		const { email } = this.state;
		if(!email) { return; }

		const options = { method: "POST" };
		fetch("/signup/" + email, options)
			.then(parseJsonResponse)
			.then(resp => {
				this.setState({
					submitted: true,
					feedback: resp
				});
			}).catch(err => {
				this.setState({
					submitted: false,
					feedback: err
				});
			});
	}

	render() {
		if(this.state.submitted) {
			return (
				<div id="signup-feedback">
					<h2>{this.state.feedback}</h2>
				</div>
			);
		}
		return (
			<div id="signup-panel" style={{ margin: 20, textAlign: "left" }}>
				<form onSubmit={this.handleSubmit}>
					<h2>Sign up to put your email on the invite list:</h2>
					<StyledInput
						id="signup-input"
						type="email"
						onChange={this.handleChange}
						value={this.state.email}
					/>
					<StyledButton type="submit" id="signup-submit">
						Submit
					</StyledButton>
					<ErrorMessage className="error" id="signup-error">
						{this.state.feedback}
					</ErrorMessage>
				</form>
			</div>
		);
	}
}
