import React, { Component } from "react";
import StyledButton from "../Elements/StyledButton";
import StyledInput from "../Elements/StyledInput";
import ErrorMessage from "../Elements/ErrorMessage";
import parseJsonResponse from "../Utilities/JsonParser";

export default class PromoCode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: "",
			submitted: false,
			feedback: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		this.setState({ code: event.target.value });
	}

	handleSubmit(event) {
		event.preventDefault(); // prevent page refresh
		const { code } = this.state;
		if(!code) { return; }

		const options = { method: "POST" };
		fetch("/redeem/" + code, options)
			.then(parseJsonResponse)
			.then(resp => {
				this.setState({
					feedback: resp,
					submitted: true
				});
				this.props.onSuccess(code);
			}).catch(err => {
				this.setState({
					feedback: err,
					submitted:false
				});
			});
	}

	render() {
		if(this.state.submitted) {
			return (
				<div id="promo-feedback">
					<h2>{this.state.feedback}</h2>
				</div>
			);
		}
		return (
			<div id="promo-panel" style={{ margin: 20, textAlign: "left" }}>
				<form onSubmit={this.handleSubmit}>
					<h2>Enter Promo Code:</h2>
					<StyledInput
						id="promo-input"
						type="text"
						onChange={this.handleChange}
						value={this.state.code}
					/>
					<StyledButton id="promo-submit" type="submit">
						Submit
					</StyledButton>
					<ErrorMessage className="error" id="promo-error">
						{this.state.feedback}
					</ErrorMessage>
				</form>
			</div>
		);
	}
}
