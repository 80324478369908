import React, { Component } from "react";
import styled from "styled-components";
import infoIcon from "../img/info.svg";
import parseJsonResponse from "../Utilities/JsonParser";
import ModalBackground from "../Elements/ModalBackground";

export default class CouponModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			feedback: "",
			completed: false,
			message: "Apply a coupon code?",
		};
		this.handleChange = this.handleChange.bind(this);
		this.applyCoupon = this.applyCoupon.bind(this);
		this.skipCoupon = this.skipCoupon.bind(this);
	}

	handleChange(event) {
		this.setState({ value: event.target.value });
	}

	applyCoupon(event) {
		event.preventDefault();
		const { value } = this.state;
		if(!value) { return; }

		fetch("/products/coupon/" + value)
			.then(parseJsonResponse)
			.then(resp => {
				this.setState({
					feedback: resp,
					message: "Success",
					value: "",
				});
				let results = JSON.stringify(this.state.feedback);
				this.props.onRedeemOrSkip(results);
			}).catch(err => {
				this.setState({
					feedback: err,
					message: "Invalid Coupon",
					value: "",
				});
			});
	}

	skipCoupon() {
		this.props.onRedeemOrSkip("null");
	}

	render() {

		if(this.state.completed) {
			return null;
		}

		return (
			<div className="modal">
				<CouponForm>
					<form onSubmit={this.applyCoupon}>
						<div>
							<img id="info-icon" src={infoIcon} alt="info icon" style={{padding: "15px 10px", color: "white", float: "left"}} />
							<h3 className="header" id="coupon-header">User Input Required</h3>
						</div>
						<div style={{padding: 15}}>
							<div id="coupon-status">{this.state.message}</div>
							<input id="coupon-input" required type="text" autoComplete="off" onChange={this.handleChange} value={this.state.value} />
						</div>
						<div className="footer">
							<button type="button" className="btn danger" id="coupon-skip" onClick={this.skipCoupon} >No</button>
							<button type="submit" className="btn success" id="coupon-apply" >Apply</button>
						</div>
						<ModalBackground id="coupon-background"/>
					</form>
				</CouponForm>
			</div>
		);
	}
}

const CouponForm = styled.div`
	background: white;
	width: 250px;
	height: 225px;
	font-size: 18px;
	border-radius: 10px;
	.header {
		background: #557090;
		color: #fff;
		font-size: inherit;
		border-bottom: 1px solid #e5e5e5;
		margin: 0px;
		padding: 15px;
		border-radius: 5px 5px 0px 0px;
	}
	input[type="text"] {
		padding: 6px 12px;
		font-size: 16px;
		border-color: #a94442;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		margin-top: 10px;
	}
	.footer {
		text-align: right;
		padding: 15px;
		border-top: 1px solid #e5e5e5;
	}
`;
