import React, { Component } from "react";
import PromoCode from "./PromoCode";
import PurchaseModal from "./PurchaseModal";
import CouponModal from "./CouponModal";
import StripeModal from "./StripeModal";

export default class PurchaseController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			promoCode: undefined,
			features: [],
			skus: [],
			total: 0,
			hostId: "",
			coupon: "",
		};
		this.handlePromoCode = this.handlePromoCode.bind(this);
		this.handleSubmission = this.handleSubmission.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleCoupon = this.handleCoupon.bind(this);
		this.renderPurchaseModal = this.renderPurchaseModal.bind(this);
		this.renderCouponModal = this.renderCouponModal.bind(this);
		this.renderStripeModal = this.renderStripeModal.bind(this);
		this.processCoupon = this.processCoupon.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.coupon !== prevState.coupon) {
			this.processCoupon();
		}
	}

	processCoupon() {
		let { total, coupon } = this.state;
		if (coupon.length <= 0 || coupon === "null") { return; }
		let couponObject = JSON.parse(coupon);
		const revisedTotal = total - (total * (couponObject.percent_off / 100));
		this.setState({ total: revisedTotal });
	}

	handlePromoCode(code) {
		this.setState({
			promoCode: code
		});
	}

	handleSubmission(selected, skuIds, total, hostId) {
		this.setState({
			features: selected,
			skus: skuIds,
			total: total,
			hostId: hostId,
		});
	}

	handleCancel() {
		this.setState({
			promoCode: undefined,
			features: [],
			skus: [],
			total: 0,
			coupon: "",
		});
	}

	handleCoupon(value) {
		this.setState({
			coupon: value
		});
	}

	renderPurchaseModal() {
		if(!this.state.promoCode || this.state.features.length) {
			return null;
		}
		return (
			<PurchaseModal
				onSubmission={this.handleSubmission}
				onCancel ={this.handleCancel}
			/>
		);
	}

	renderCouponModal() {
		if(!this.state.features.length || this.state.coupon.length) {
			return null;
		}
		return (
			<CouponModal
				onRedeemOrSkip={this.handleCoupon}
				onCancel ={this.handleCancel}
			/>
		);
	}

	renderStripeModal() {
		if(!this.state.coupon.length) {
			return null;
		}
		return (
			<StripeModal
				onCancel={this.handleCancel}
				coupon={this.state.coupon}
				skus={this.state.skus}
				total={this.state.total}
				hostId={this.state.hostId}
				promoCode={this.state.promoCode}
			/>
		);
	}

	render() {
		return (
			<div id="purchase-controller">
				<PromoCode onSuccess={this.handlePromoCode} />
				{this.renderPurchaseModal()}
				{this.renderCouponModal()}
				{this.renderStripeModal()}
			</div>
		);
	}
}
