import React, { Component } from "react";
import SignUp from "./SignUp";
import PurchaseController from "./PurchaseController";
import StyledButton from "../Elements/StyledButton";
import styled from "styled-components";

export default class SolicitInteraction extends Component {
	constructor(props) {
		super(props);
		this.state = { redeem: false, signup: false };
		this.displayRedeemForm = this.displayRedeemForm.bind(this);
		this.displaySignupForm = this.displaySignupForm.bind(this);
		this.resetForms = this.resetForms.bind(this);
	}

	displayRedeemForm() {
		this.setState({ redeem: true });
	}
	displaySignupForm() {
		this.setState({ signup: true });
	}
	resetForms() {
		this.setState({ redeem: false, signup: false });
	}

	render() {
		if(this.state.redeem) {
			return (
				<div>
					<PurchaseController />
					<StyledButton id="back-button" onClick={this.resetForms}>Back</StyledButton>
				</div>
			);
		}
		if(this.state.signup) {
			return (
				<div>
					<SignUp />
					<StyledButton id="back-button" onClick={this.resetForms}>Back</StyledButton>
				</div>
			);
		}
		return (
			<div>
				<h2 id="solicit-header">Redeem A Limited Access Code -- OR -- Signup To Be Invited:</h2>
				<StyledButton id="display-redeem-button" onClick={this.displayRedeemForm}>Redeem</StyledButton>
				<StyledButton id="display-signup-button" onClick={this.displaySignupForm}>Signup</StyledButton>
			</div>
		);
	}
}
