import React, { Component } from "react";
import styled from "styled-components";
import logo from "../img/ai_logo.png";
import parseJsonResponse from "../Utilities/JsonParser";

export default class Footer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			version: ""
		};
		this.fetchVersion();
	}

	fetchVersion() {
		fetch("/version")
			.then(parseJsonResponse)
			.then(resp => {
				this.setState({
					version: "v"+resp
				});
			});
	}

	render() {
		return (
			<StyledFooter>
				<div>
					<img src={logo} alt="Automation Integrated Logo" />
				</div>
				<div>
					<strong>Automaton Integrated</strong>
				</div>
				<div>1304 Sovereign Row</div>
				<div>Oklahoma City, OK 73108</div>
				<div>405.423-4241</div>
				<div>
					<a href="https://www.ai-sys.com">www.ai-sys.com</a>
				</div>
				<div style={{marginTop:"20px"}}>
					{this.state.version}
				</div>
			</StyledFooter>
		);
	}
}

const StyledFooter = styled.footer`
	text-align: center;
	padding: 1em;
	line-height: 1.2em;
	img {
		display: inline-block;
		height: 120px;
		vertical-align: top;
		padding: 1em;
	}
`;
