import styled from "styled-components";

const CallToAction = styled.div`
	padding: 1em 2em;
	text-align: center;
	a {
		border: 2px solid #e19b53;
		border-radius: 290486px;
		color: white;
		cursor: pointer;
		font-size: 0.6em;
		letter-spacing: 0.2em;
		padding: 1em 2em;
		text-transform: uppercase;
		text-decoration: none;
		background-color: #e19b53;
		transition: none 200ms ease-out;
		transition-property: color, background;
	}
	a:hover {
		background: none;
		color: black;
	}
`;

export default CallToAction;
