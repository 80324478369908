import React from "react";
import CallToAction from "../Elements/CallToAction";

const Paragraphs = () => (
	<>
		<h2>"Best-In-Class Control Sequences..."</h2>
		<p>
			<a href="https://www.ai-sys.com">Automation Integrated</a>'s Sequence
			Blocks are an innovative way for deploying cost saving energy
			optimizations based on standards like <strong>ASHRAE</strong> 36P and 1455
			RP. By leveraging
			<a href="https://www.tridium.com/products-services/niagara4">Niagara 4</a>
			's semantic data model, Sequence Blocks are able to deliver{" "}
			<strong>control sequences</strong> of the highest standard.
		</p>

		<p>
			Our Sequence Blocks are simple to install, reliable, and can dramatically
			reduce your energy costs.
		</p>

		<CallToAction>
			<p>
				<a href="https://www.blueforge.us">buy it now on Blueforge.us!</a>
			</p>
		</CallToAction>

		<h2>Unrivaled Simplicity</h2>
		<p>
			From installation to customization of different setpoints and zones,
			Sequence Blocks makes management as straightforward and easy as possible.
			Many solutions sacrifice customizability and control for the sake of
			simplicity and ease of use. Sequence Blocks, however, allows you to
			achieve both.
		</p>

		<h2>Comfort Without Compromise</h2>
		<p>
			Reducing unnecessary loads on your systems, customizing zones, and setting
			your own priorities allow for a dramatic reduction in energy consumption.
			This reduction doesn't mean restriction. One of the truly innovative
			aspects of Sequence Blocks is the ability to reduce energy consumption
			without compromising comfortability. With Sequence Blocks, the only
			perceptible difference will be in your energy expenditures, not the
			temperature/lighting/etc of your facilities.
		</p>

		<h2>Saves Money Your Very First Month</h2>
		<p>
			HVAC sequences have always saved customers money, but usually require
			expensive energy audits and hundreds of thousands of dollars.{" "}
			<strong>Sequence Blocks</strong> does the same thing for much less. It
			actually <a href="https://www.blueforge.us">pays for itself</a>!
		</p>

		<CallToAction>
			<p>
				<a href="https://www.blueforge.us">buy it now on Blueforge.us!</a>
			</p>
		</CallToAction>

		<h2>Current Sequences</h2>
		<h3>Duct Static Pressure Reset</h3>
		<ul>
			<li>
				<strong>Save up to 50%</strong> of fan energy
			</li>
			<li>Simple deployment using N4 tagging/relationships</li>
			<li>
				Utilizes ASHRAE{" "}
				<a href="https://www.scribd.com/document/370776882/ASHRAE-Journal-Trim-and-Respond">
					1455 RP
				</a>{" "}
				Trim & Respond logic
			</li>
			<li>Applies zone importance multipliers</li>
			<li>Allows rogue zone identification.</li>
		</ul>

		<h3>Supply Air Temperature Reset</h3>
		<p>
			Yes, <strong>Sequence Blocks</strong> can now provide supply air
			temperature resets! Check back for more updates.
		</p>

		<h2>Painless Install</h2>
		<p>
			Login/Register on <a href="https://blueforge.us">BlueForge.us</a>,
			download the module and buy your license instantly. Then follow the 5
			minute video below to configure your block and start{" "}
			<strong>saving money immediately!</strong>
		</p>
		<iframe
			title="Sequence Block Installation"
			width="560"
			height="315"
			src="https://www.youtube.com/embed/0q5IxBhN_JU"
			frameBorder="0"
			allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>

		<h2>Even more to come:</h2>
		<ul>
			<li>Loop D/P Reset</li>
			<li>HW Supply Temperature Reset</li>
			<li>CHW Supply Temperature Reset</li>
			<li>Night Setback/Setup and Overrides</li>
			<li>Zone Groups</li>
		</ul>

		<CallToAction align="center">
			<h4>Downloads:</h4>
			<p>
				<a href="https://files.blueforge.us/public/file/aitrim-rt.jar">
					Niagara v4.4
				</a>
			</p>
		</CallToAction>

		<h2>Who We Are</h2>

		<p>
			<a href="https://www.ai-sys.com">AUTOMATION INTEGRATED'S</a> Building
			Automation and Control Systems portfolio combines the best building
			technologies available, whether you need open framework software solutions
			like Niagara AX/N4 or open-source web-scale applications. AI's product
			lines connect our clients' facilities into the future.
		</p>
	</>
);

export default Paragraphs;
