import styled from "styled-components";

const StyledButton = styled.button`
	background-color: #e19b53;
	border-radius: 10px;
	border: 2px solid #e19b53;
	cursor: pointer;
	display: block;
	margin: 10px;
	padding: 10px;
	text-align: left;
	display: inline-block;
`;

export default StyledButton;
