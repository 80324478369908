/* eslint class-methods-use-this: 0 */
import React, {Component} from "react";
import {Elements, StripeProvider} from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";
import ModalBackground from "../Elements/ModalBackground";

class StripeModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			completed: false,
		};
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose() {
		this.props.onCancel();
		this.setState({
			completed: true
		});
	}

	render() {

		if(this.state.completed) {
			return null;
		}

		return (
			<div className="modal">
				<StripeProvider apiKey={process.env.STRIPE_PUBLIC_KEY}>
					<div className="stripe-modal">
						<h2 id="stripe-header">Checkout</h2>
						<Elements>
							<CheckoutForm onClose={this.handleClose} {...this.props} />
						</Elements>
					</div>
				</StripeProvider>
				<ModalBackground id="stripe-background"/>
			</div>
		);
	}
}

export default StripeModal;
