import React, { Component } from "react";
import styled from "styled-components";
import parseJsonResponse from "../Utilities/JsonParser";
import ModalBackground from "../Elements/ModalBackground";

const hostIdPattern = "^[A-Z][A-Za-z]{2}-{1}[A-Z0-9]{4,5}-{1}[A-Z0-9]{4}-{1}[A-Z0-9]{4}-{1}[A-Z0-9]{4}-{0,1}[A-Z0-9]{0,4}$";

export default class PurchaseModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			features: [],
			total: 0,
			selected: [],
			host_id: "",
			feedback: "",
			value: "",
			log: [],
			skus: [],
			completed: false,
		};
		this.toggle = this.toggle.bind(this);
		this.resetState = this.resetState.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleSelection = this.handleSelection.bind(this);
		this.handleHostIdChange = this.handleHostIdChange.bind(this);
		this.getProducts = this.getProducts.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.getProducts();
	}

	getProducts() {
		fetch("/products")
			.then(parseJsonResponse)
			.then(resp => {
				this.setState({ features: resp });
			}).catch(err => {
				this.setState({ features: [], feedback: "An Unexpected Error Occurred" });
			});
	}

	toggle(event) {
		const i = event.target.id;
		let { features, log, skus, selected } = this.state;
		const feature = features[i];

		if(!feature.checked) {
			selected.push(feature.name);
			feature.checked = true;
			this.setState({ features, selected, error: "" });
		} else {
			selected.splice(selected.indexOf(feature.name), 1);
			feature.checked = false;
			skus[i] = 0;
			log[i] = 0;
			let total = log.reduce((acc, cur) => acc + cur);
			this.setState({ features, selected, skus, total, error: "" });
		}
	}

	resetState() {
		const { features } = this.state;
		const reset = features.map(feature => {
			feature.checked = false;
			return feature;
		});
		this.setState({
			features: reset,
			total: 0,
			selected: [],
			host_id: "",
			feedback: "",
			value: "",
			log: [],
			error: "",
		});
	}

	handleCancel() {
		this.resetState();
		this.props.onCancel();
		this.setState({ completed: true });
	}

	handleSubmit(event) {
		event.preventDefault();
		const { selected, skus, total, host_id } = this.state;

		if(!selected || !selected.length) {
			this.setState({ error: "No product selected" });
			return;
		}
		if(!skus || skus.filter(s => s).length!==selected.length || !total) {
			this.setState({ error: "No product option selected" });
			return;
		}

		const filteredSKUs = skus.filter(sku => sku);

		this.props.onSubmission(selected, filteredSKUs, total, host_id);
		this.setState({ completed: true });
	}

	balanceSKUIdArray(featureID, skuID=0) {
		let { skus } = this.state;
		skus[featureID] = skuID;
		this.setState({ skus });
	}

	handleSelection(event) {
		let { log } = this.state;
		if(event.target.value.length <= 1) {
			this.balanceSKUIdArray(event.target.value);
			log[event.target.value] = 0;
		} else {
			const [featureID, price, skuID] = event.target.value.split(",");
			log[featureID] = parseInt(price);
			this.balanceSKUIdArray(featureID, skuID);
		}
		let total = log.reduce((acc, cur) => acc + cur);
		this.setState({ value: event.target.value, total, error: "" });
	}

	handleHostIdChange(event) {
		const hostId = event.target.value;
		const regex = RegExp(hostIdPattern);
		const valid = regex.test(hostId);
		this.setState({ host_id: hostId, error: valid? "" : "Invalid Host Id" });
	}

	getFeatureOptions(feature,i) {
		if(!feature.checked) { return; }
		const { log } = this.state;
		return (
			<React.Fragment>
				<select className="feature-option-select" onChange={this.handleSelection}>
					<option value={i} key={feature.name+"sku-placeholder"}>Select An Option:</option>
					{feature.skus.map(sku => (
						<option value={[i, sku.price, sku.id]} key={feature.name+sku.id.slice(-4)} >
							{sku.summary}
						</option>
					))}
				</select>
				<div style={{ textAlign: "right" }}>${isNaN(log[i]) ? 0 : log[i] * 0.01}</div>
			</React.Fragment>
		);
	}

	renderMappedFeatures() {
		const { features } = this.state;
		const mappedFeatures = features.map((feature, i) => {
			return (
				<div className="row" key={feature.name} >
					<div>
						<div className={feature.checked ? "checked" : "unchecked"} id={i} onClick={this.toggle}/>
						<label className="feature-labels" id={i} onClick={this.toggle}>{feature.desc}</label>
					</div>
					{this.getFeatureOptions(feature,i)}
				</div>
			);
		});
		return (
			<div className="container">
				{mappedFeatures}
			</div>
		);
	}

	renderTotal() {
		return (
			<div style={{paddingRight: 15}}>
				<div className="row" id="total">
					<div />
					<div className="total-border">Total</div>
					<div className="total-border" id="purchase-total" style={{ textAlign: "right" }}>
						${isNaN(this.state.total) ? 0 : this.state.total * 0.01}
					</div>
				</div>
			</div>
		);
	}

	renderHostIdInput() {
		return (
			<div style={{ marginBottom: 30, paddingLeft: 15 }}>
				<label style={{ display: "block", marginBottom: 5 }}>Host ID</label>
				<input
					type="text"
					id="lic-hostid"
					pattern={hostIdPattern}
					placeholder="Enter Host Id"
					required
					value={this.state.host_id}
					onChange={this.handleHostIdChange}
				/>
			</div>
		);
	}

	renderFooter() {
		return (
			<div className="footer">
				<span id="error-feedback" style={{display:"inline", padding: 20, color: "red"}}>{this.state.error}</span>
				<button className="btn danger" id="purchase-cancel" type="button" onClick={this.handleCancel}>
					Cancel
				</button>
				<button className="btn success" id="purchase-submit" type="submit">
					Submit
				</button>
			</div>
		);
	}

	render() {
		if(this.state.completed) {
			return null;
		}

		return (
			<div className="modal">
				<PurchaseForm>
					<form onSubmit={this.handleSubmit}>
						<h3 id="purchase-header" className="header">Purchase License</h3>
						<h4>{this.state.feedback}</h4>
						<div id="purchase-instructions" style={{paddingLeft: 15}}>Select License Features</div>
						{this.renderMappedFeatures()}
						{this.renderTotal()}
						{this.renderHostIdInput()}
						{this.renderFooter()}
						<ModalBackground id="purchase-background" />
					</form>
				</PurchaseForm>
			</div>
		);
	}
}


const PurchaseForm = styled.div`
	font-size: 18px;
	background: white;
	width: 900px;
	border-radius: 15px;
	.header {
		background: #557090;
		color: #fff;
		font-size: inherit;
		padding: 15px;
		border-bottom: 1px solid #e5e5e5;
		margin-top: 0px;
		border-radius: 10px 10px 0px 0px;
	}
	.container {
		margin-top: 20px;
		padding: 0 15px;
		margin-right: auto;
		margin-left: auto;
	}
	.row {
		margin-top: 10px;
		margin-bottom: 10px;
		height: 34px;
		display: grid;
		grid-template-columns: 50% 40% auto;
		grid-column-gap: 15px;
	}
	.row select {
		font-size: inherit;
		height: 34px;
	}
	.total-border {
		border-top: dotted #ababab 1px;
		padding-top: 5px;
	}
	input[type="text"] {
		width: 45%;
		padding: 6px 12px;
		font-size: 16px;
		border-color: #a94442;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	}
	.footer {
		text-align: right;
		padding: 15px;
		border-top: 1px solid #e5e5e5;
	}
`;
